import React from "react";
import "./Footer2.css"

function Footer2() {
  return (
  <>
  <div className="footer2">
    <p>Eduction,All Rights Reserved. Design - <span className="color" > By Blankslate</span></p>
  </div>
  </>
  );
}

export default Footer2;