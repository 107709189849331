import React from "react";
import Lunch from "./Lunch";
import Popular from "./Popular";
import Organization from "./Organization";
import Popular1 from "./Popular1";
import Register from "./Register";
function Home() {
    return (
      <>
   <Lunch/>
   <Popular1/>
   <Organization/>
   <Register/>
   <Popular/>


    </>
    );
  }
  
  export default Home;
  
