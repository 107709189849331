import React from "react";
import "./Popular.css"

function  Popular() {
    return (
      <>
     <div className="popular">
        <div className="desc">
        <div className="heading">
            <p>our Most  popular  news</p>
        </div>
        <div className="d">
        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolores assumenda incidunt porro dolor lorem, ipsum dolor Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi vitae.

        </div>
        <div className="btn">
            <button>Read more</button>
        </div>
        </div>
        <div className="pic">
            <img src="https://blank-slate-app.netlify.app/img/school-1782427_1920.jpg" alt="" />
        </div>
     </div>
    </>
    );
  }
  
  export default Popular;