import React from "react";
import "./Organization.css"

function Organization() {
    return (
      <>
     <div className="organization">
        <div className="image">
            <img src="https://blank-slate-app.netlify.app/img/teacher-4784917_1920.jpg" alt="" />
        </div>
        <div className="organization-detail">
            <div className="head">
                be a part  of  our organization
            </div>
            <div className="para">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolores assumenda incidunt porro dolor lorem, ipsum dolor Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi vitae.

ALL COURSESLEARN MORE
            </div>
            <div className="btn">
                <button>All courses</button>
            </div>
        </div>
     </div>
    </>
    );
  }
  
  export default Organization;
  
