import React from "react";
import Servic from "./Servic";

function Services() {
    return (
    
   <Servic/>
   
    
   
    );
  }
  
  export default Services;
  