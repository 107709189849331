import React from "react";
import "./Register.css"

function Register() {
    return (
      <>
     <div className="register">
        <div className="course">
            <p>huppy up ! Register  for our  new Courses</p>
        </div>
        <div className="btn">
            <button>all Courses</button>
        </div>
             </div>
    </>
    );
  }
  
  export default Register;