import React from "react";

import "./Popular1.css"

function  Popular1() {
    return (
      <>
     <div className="popular">
        <div className="desc">
        <div className="heading">
            <p>our Most  popular  news</p>
        </div>
        <div className="d">
        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolores assumenda incidunt porro dolor lorem, ipsum dolor Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi vitae.

        </div>
        <div className="btn">
            <button>Read more</button>
        </div>
        </div>
        <div className="pic">
            <img src="https://blank-slate-app.netlify.app/img/dl.beatsnoop.com-1024-1631967839.jpg" alt="" />
        </div>
     </div>
    </>
    );
  }
  
  export default Popular1;