import React from "react";
import "./Lunch.css"

function Lunch() {
    return (
      <>
     
   <div className="lunch">
    <div className="lunch1">
    <div className="heading">
        learn & Launch
    </div>
    <div className="heading2">
        launch your own online learning platform
    </div>
    <div className="para">
    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolores assumenda incidunt porro dolor lorem, ipsum dolor Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi vitae.
    </div>
<div className="btn">
    <button>view all courses</button>
</div>
</div>
<div className="box">
    
</div>
   </div>

    </>
    );
  }
  
  export default Lunch;
  

